
































































//TODO: Disable save btn if fetching

import Vue from 'vue';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import { fetchProjectPresetImages, getAllProjectPrivacyStates, isImageEditorInEditProjectModal } from '@/common/config';
import { ProjectModel } from '@/common/classes/models';
import { v4 as uuidv4 } from 'uuid';
import TariffService from '@/common/tariff.service';
import { ProjectPrivacyState } from '@/common/types/project.types';
import ProjectImageEditor from '@/components/constructor-product-tab/ProjectImageEditor.vue';

const MODAL_WIDTH = 540;
const MODAL_HEIGHT = 840;
type PrivacyStateType = ('private'|'protected'|'public');

export default Vue.extend({
    name: 'modalProject',
    data(){
        return{
            modalWidth: MODAL_WIDTH,
            modalHeight: MODAL_HEIGHT,
            deleteProjectState: 'init' as ('init'|'confirm'),
        }
    },
    mixins: [MiscMixin],
    props: {unsavedChanges:{type:Boolean}},
    components: { ProjectImageEditor },
    created():void{
        this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth - 100 : MODAL_WIDTH;
        this.modalHeight = window.innerHeight < MODAL_HEIGHT ? window.innerHeight - 40 : MODAL_HEIGHT;
    },
    async mounted():Promise<void>{
        this.assignAvaliblePrivacyState();
        this.setRandomImageIfNeeded(this.isLoadingProject);
    },
    watch: {
        isAuthenticated(){ this.assignAvaliblePrivacyState(); },
        projectPrivacyState(){ this.assignAvaliblePrivacyState(); },
        isLoadingProject(loading:boolean){ 
            this.setRandomImageIfNeeded(loading);
        },
    },
    methods: {
        async setRandomImageIfNeeded(loading:boolean):Promise<void>{
            const isImageNeeded = () => !loading && !this.projectData.imageLink;

            if(isImageNeeded()) {
                const {imageSet} = await fetchProjectPresetImages();
                
                if(!isImageNeeded()) return;

                const randomImageIndex = Math.floor(Math.random() * imageSet.length)
                this.$set(this.projectData, 'imageLink', imageSet[randomImageIndex])
            }
        },
        assignAvaliblePrivacyState():void{
            const curState = this.projectPrivacyState;
            const enabledStates = this.enabledProjectPrivacyStates
            const isCurStateAvalible = enabledStates.map((s)=>s.name).includes( curState );
            if( !isCurStateAvalible )
                this.projectPrivacyState = this.enabledProjectPrivacyStates[0].name as PrivacyStateType;
        },
        hideModal():void{
            this.$modal.hide('edit-project-info');
        },
        deleteProjectClick():void{
            switch (this.deleteProjectState){
                case 'init':
                    this.deleteProjectState = 'confirm';
                    setTimeout(() => this.deleteProjectState = 'init', 4000);
                    break;
                case 'confirm':
                    ProjectModel.deleteProject(undefined,this.projectData.id,undefined,()=>{
                        this.$modal.hide('edit-project-info');
                        location.reload();
                    });
                    break;
            }
        },
        publishedClick():void{
            let switchTo:PrivacyStateType = 'private';
            switch(this.projectPrivacyState){
                case 'private': switchTo = 'protected'; break;
                case 'protected': switchTo ='public'; break;
                case 'public': switchTo = 'private'; break;
            }
            this.projectPrivacyState = switchTo;
        },
        submitEdit():void{
            const uploadUserImagePromise = (this as any).$refs.imageEditor?.uploadUserImagePromise;
            this.$emit('save-project', {uploadUserImagePromise});
            this.$modal.hide('edit-project-info');
        },
    },
    computed: {
        isImageEditorInEditProjectModal(){
            return isImageEditorInEditProjectModal();
        },
        allProjectPrivacyStates():ProjectPrivacyState[]{
            return getAllProjectPrivacyStates();
        },
        //NOTE: these project privacy states are changed in config.ts file
        enabledProjectPrivacyStates():ProjectPrivacyState[]{
            const allPrivacyStates = this.allProjectPrivacyStates;
            return TariffService.getEnabledProjectPrivacyStates(this.user,allPrivacyStates);
        },
        projectPrivacyState: {
            get():PrivacyStateType{
                const {isPublished, invitationCode} = this.projectData;
                if(isPublished) return 'public';
                else if(invitationCode) return 'protected';
                else return 'private';
            },
            set(state:PrivacyStateType):void{
                let setPublished;
                let setInvitationCode;
                switch(state){
                    case 'private':
                        setPublished = false;
                        setInvitationCode = false;
                        break;
                    case 'protected':
                        setPublished = false;
                        setInvitationCode = true;
                        break;
                    case 'public':
                        setPublished = true;
                        setInvitationCode = false;
                        break;
                }
                this.$set(this.projectData,'isPublished',setPublished);
                this.$set(this.projectData,'invitationCode',setInvitationCode ? uuidv4() : undefined);
            },
            cache: false,
        },
        privacyBtnText():string{
            switch (this.projectPrivacyState){
                case 'private': return 'Виден только мне';
                case 'protected': return 'Доступен по ссылке';
                case 'public': return 'Виден всем';
                default: throw new Error("Unrecognized projectPrivacyState "+this.projectPrivacyState);
            }
        },
        ...mapGetters(['projectData','isAuthenticated','user','isLoadingProject'])
    },
})
